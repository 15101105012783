<template>
  <div class="w-100 h-auto">
    <custom-analytic-card
      :title="title"
    >
      <table class="custom-table">
        <thead>
          <tr>
            <td>Team</td>
            <td class="text-right">
              Users
            </td>
            <td class="text-right">
              Number of Job Postings
            </td>
            <td class="text-right">
              Number of Clicks
            </td>
            <td class="text-right">
              Actions
            </td>
          </tr>
        </thead>

        <tbody v-if="$isNotEmpty(reportData)">
          <tr
            v-for="value, key, index in reportData.team_report_table"
            :key="value.key"
          >
            <td class="font-weight-bold">
              {{ value.team }}
            </td>
            <td class="text-right">
              {{ $length(value.users) }}
            </td>
            <td class="text-right">
              {{ value.number_of_job_postings }}
            </td>
            <td class="text-right">
              {{ value.number_of_clicks }}
            </td>
            <td>
              <div class="d-flex flex-row flex-gap-md justify-content-end">
                <b-button
                  variant="outline-danger"
                  class="font-weight-bold d-flex flex-row flex-gap-md align-items-center"
                  size="sm"
                  @click="downloadTeamReport([value.team], 'pdf')"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                  PDF
                </b-button>

                <!-- <b-button
                  variant="outline-success"
                  class="font-weight-bold d-flex flex-row flex-gap-md align-items-center"
                  size="sm"
                  @click="downloadTeamReport([value.team], 'excel')"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="16"
                  />
                  Excel
                </b-button> -->
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </custom-analytic-card>
  </div>
</template>

<script>
import { subDays } from 'date-fns'
import CustomAnalyticCard from '../CustomAnalyticCard.vue'

export default {
  components: {
    CustomAnalyticCard,
  },
  props: {
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '350',
    },

    whichReports: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      title: "Teams' Report",
      reportData: {},
      colors: [
        this.$themeColors.primary,
        this.$themeColors.dark,
      ],
    }
  },
  computed: {
    computedWhichReports() {
      if (this.$isNotEmpty(this.whichReports)) {
        return this.whichReports
      }

      return ['team_report_table', 'team_report_statistics']
    },
  },
  created() {
    this.initComponent()
  },
  methods: {
    initComponent(teams = []) {
      const reportId = this.$route.params.report_name.split('__')[1]

      const date_to = new Date()
      const date_from = subDays(date_to, 30)
      // const formattedDate = thirtyDaysAgo.toISOString().split('T')[0] // YYYY-MM-DD format

      this.isLoading = true
      this.$http
        .post('/api/event-reports/analytics', {
          form_data: {
            fetch_data: this.computedWhichReports,
            report_id: reportId,
            teams,
            date_from,
            date_to,
          },
        })
        .then(response => {
          const { reports } = response.data
          if (this.computedWhichReports.includes('team_report_table')) {
            this.$set(this.reportData, 'team_report_table', reports.team_report_table)
          }
          if (this.computedWhichReports.includes('team_report_statistics')) {
            this.$set(this.reportData, 'team_report_statistics', reports.team_report_statistics)
          }

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },
    downloadTeamReport(teams, docType) {
      const reportId = this.$route.params.report_name.split('__')[1]

      const date_to = new Date()
      const date_from = subDays(date_to, 30)
      // const formattedDate = thirtyDaysAgo.toISOString().split('T')[0] // YYYY-MM-DD format

      this.isLoading = true
      this.$http
        .post('/api/event-reports/analytics/download', {
          form_data: {
            fetch_data: this.computedWhichReports,
            report_id: reportId,
            download: docType,
            teams,
            date_from,
            date_to,
          },
          responseType: "arraybuffer",
        })
        .then(response => {
          let filename = response.headers['content-disposition'].split('filename=')[1].trim()
          if (
            filename[0] === "'"
              || filename[0] === '"'
          ) {
            filename = filename.slice(1, -1)
          }
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

          const fileURL = window.URL.createObjectURL(blob)
          const fileLink = document.createElement('a')

          // console.log(`Downloaded file size: ${new Blob([response.data]).size / 1024} KB`);

          fileLink.href = fileURL
          fileLink.setAttribute('download', filename)
          document.body.appendChild(fileLink)
          fileLink.click()
          document.body.removeChild(fileLink)

          // // Cleanup: Revoke the object URL after download
          // window.URL.revokeObjectURL(link.href)

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
