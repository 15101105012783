<template>
  <div>
    <b-col cols="12">
      <b-card class="">
        <b-row class="w-100 m-0 p-0">
          <b-col
            col
            cols="12"
            xl="3"
            md="3"
            class=""
          >
            <b-button
              variant="primary"
              icon-pack="feather"
              icon="icon-arrow-left"
              :class="{'w-100': isMobile}"
              @click="$router.push({ name: 'platform-analytics' })"
            >Go Back</b-button>
          </b-col>
          <b-col
            col
            cols="12"
            xl="6"
            md="6"
            class="text-center mt-1 mt-xl-0 mt-md-0"
          >
            <!-- <h3>Reports Name</h3> -->
            <h3
              class="m-0 p-0"
              style="margin-top: 10px!important;"
            >
              {{ $route.params.report_name.split('__')[0] }}
            </h3>
          </b-col>

          <b-col
            cols="3"
            class="text-right"
          >
            <!-- <b-button
              variant="primary"
              icon-pack="feather"
              icon="icon-edit-2"
              @click="isEditReport = !isEditReport"
            >Edit Report</b-button> -->
            <b-button
              variant="dark"
              icon-pack="feather"
              class="shadow-lg mx-1"
              @click="downloadPDF"
            >Download Report</b-button>
          </b-col>
        </b-row>
      </b-card>

      <b-tabs
        lazy
        pills
        justified
        align="center"
        class="tab-action-btn-fill-container"
      >
        <!-- <b-tab
          lazy
        >
          <template #title>
            <span class="">Pre Event</span>
          </template>

          <pre-event
            :is-edit-report="isEditReport"
            :report-data="reportData"
          />
        </b-tab> -->

        <!-- <b-tab lazy>
          <template #title>
            <span class="">During Event</span>
          </template>

          <during-event
            :is-edit-report="isEditReport"
            :report-data="reportData"
          />
        </b-tab> -->

        <!-- <b-tab lazy>
          <template #title>
            <span class="">Post Event</span>
          </template>

          <post-event :report-data="reportData" />
        </b-tab -->

        <b-tab
          ref="pdfContent"
          lazy
        >
          <template #title>
            <span class="">Summary</span>
          </template>
          <!-- <b-overlay
            variant="white"
            :show="isLoading"
            spinner-variant="primary"
            blur="0"
            opacity=".75"
            rounded="sm"
            style="width: 100%;"
          > -->
            <platform-analytics-summary
              :report-data="reportData"
              :analytics-config="analyticsConfig"
            />
          <!-- </b-overlay> -->
        </b-tab>
      </b-tabs>
    </b-col>
  </div>
</template>
<script>
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import { onResizeMixins } from '@/components/mixins/onResizeMixins'

// tabs
// import PreEvent from './components/PreEvent.vue'
// import DuringEvent from './components/DuringEvent.vue'
// import PostEvent from './components/PostEvent.vue'
import PlatformAnalyticsSummary from './components/PlatformAnalyticsSummary.vue'

export default {
  components: {
    // tabs
    // PreEvent,
    // DuringEvent,
    // PostEvent,
    PlatformAnalyticsSummary,
  },
  mixins: [onResizeMixins],
  data() {
    return {
      analyticsConfig: {},

      eventId: null,
      renderComponent: true,
      isEditReport: false,
      isLoading: false,
      capturedImage: null,
      reportData: {
        statistics: {
          charts: {
            job_seeker: {},
            employer: {},
            application_form_chart: {},
          },
        },
      },
    }
  },
  watch: {
  },
  created() {
    // this.getData()
    this.initAnalyticsConfig()
  },
  mounted() {
    this.eventId = this.$route.params.report_name.split('__')[1]
  },
  methods: {
    initAnalyticsConfig() {
      const reportId = this.$route.params.report_name.split('__')[1]

      this.$http
        .post(`/api/event-reports-config`, {
          form_data: {
            report_id: reportId,
          },
        })
        .then(response => {
          const { success, output } = response.data

          this.analyticsConfig = {}
          if (success) {
            this.analyticsConfig = output
          } 
        })
        .catch(() => {
          //
        })
    },
    
    captureElement() {
      this.$nextTick(() => {
        const pdfContent = this.$refs.pdfContent.$el

        // html2canvas(pdfContent).then(canvas => {
        //   this.capturedImage = canvas.toDataURL()
        //   console.log(this.captureElement)
        //   this.downloadImage()
        // })

        html2canvas(pdfContent).then(canvas => {
          const imageData = canvas.toDataURL('image/png')
          const pdf = new jsPDF('p', 'mm', 'a4')

          const width = pdf.internal.pageSize.getWidth()
          const height = pdf.internal.pageSize.getHeight()

          pdf.addImage(imageData, 'PNG', 0, 0, width, height)
          pdf.save('event_report.pdf')
        })
      })
    },
    downloadImage() {
      if (this.capturedImage) {
        const link = document.createElement('a')
        link.href = this.capturedImage
        link.download = 'captured_image.png'
        link.click()
      }
    },
    downloadPDF() {
      this.captureElement()
    },
    removeChart(uen) {
      this.charts = this.charts.filter(chart => {
        if (chart.uen !== uen) return chart
      })
    },

    getData() {
      this.isLoading = true
      const eventId = this.$route.params.report_name.split('__')[1]

      this.$http
        .get(`/api/event-reports/${eventId}`)
        .then(response => {
          const output = response.data
          const data = output.reports

          // this.$set(this.reportData.statistics.charts.application_form_chart, 'submitted_applications', data.application_form_chart.submitted_applications)
          // this.$set(this.reportData.statistics.charts.application_form_chart, 'abandoned_applications', data.application_form_chart.abandoned_applications)

          // this.$set(this.reportData.statistics.charts.application_form_chart, 'abandoned_applications', data.application_form_chart.abandoned_applications)

          // this.$set(this.reportData.statistics.charts.job_seeker, 'gender', {
          //   Male: data.males_count,
          //   Female: data.females_count,
          // })

          // this.$set(this.reportData.statistics.charts.job_seeker, 'highest_qualification_count', data.highest_qualification_count)
          // this.$set(this.reportData.statistics.charts.job_seeker, 'citizenship_count', {
          //   Singaporean: data.citizenship_singaporean_count,
          //   'Singaporean PR': data.citizenship_singaporean_pr_count,
          //   Other: data.citizenship_other_count,
          // })

          // this.$set(this.reportData.statistics, 'number_of_employers_account', data.total_employers)
          // this.$set(this.reportData.statistics, 'number_of_users_account', data.total_applicants)

          // this.$set(this.reportData.statistics.charts.job_seeker, 'employment_status', {
          //   employed: data.employment_status_employed_count,
          //   unemployed_for_less_than_6_months: data.employment_status_unemployed_for_less_than_6_months_count,
          //   unemployed_for_more_than_6_months: data.employment_status_unemployed_for_more_than_6_months_count,
          // })

          // this.$set(this.reportData.statistics.charts.job_seeker, 'users_activity_per_month', data.job_seeker_login_chart)
          // this.$set(this.reportData.statistics.charts.job_seeker, 'job_applications_chart', data.job_applications_chart)

          // this.$set(this.reportData.statistics.charts.employer, 'users_activity_per_month', data.employer_login_chart)
          // this.$set(this.reportData.statistics.charts.employer, 'active_job_chart', data.active_job_chart)

          // this.$set(this.reportData.statistics, 'avg_bounce_rate', data.avg_bounce_rate)

          // this.$set(this.reportData.statistics, 'event_job_visit', data.event_job_visit)
          // this.$set(this.reportData.statistics, 'job_visit', data.job_visit)
          // this.$set(this.reportData.statistics, 'apply_now_clicks', data.apply_now_clicks)

          // this.$set(this.reportData.statistics, 'tracking', data.tracking)

          this.renderComponent = false
          setTimeout(() => {
            this.renderComponent = true
          }, 299)

          // this.reportData.statistics.charts.job_seeker = {
          // }
          this.isLoading = false
        })
        .catch(error => {
          console.error(error)
          this.$toast(
            {
              component: this.$toastContent,
              props: {
                title: 'Operation Failed',
                icon: 'CheckCircleIcon',
                text: 'Could not retrieve the event reports. Please try again later.',
                variant: 'danger',
              },
            },
            { timeout: this.$longestTimeout },
          )

          this.isLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/variables.scss';

.back-button {
  background-color: $sidebar-dark-bg;
}
</style>
